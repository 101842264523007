<template>
    <div class="main">
        <div class="main1">
            <div class="top">
                <van-image class="t1" round :src="sum.avatar" fit="fill" />
                <div class="t2">
                    <div class="t21">{{ sum.nick_name }}</div>
                    <div class="t22">ID: {{ sum.user_id }}</div>
                </div>
            </div>
        </div>
        <div class="tline"></div>
        <div style="margin-left: 10px">
            <div class="f1">注册来源：{{ sum.from }}</div>
            <div class="f1">注册时间：{{ sum.register_time }}</div>
        </div>

        <div class="tline"></div>
        <div style="margin-left: 10px">
            <div class="f1">当前余额：{{ sum.recharge_amount }}</div>
            <div class="f1">累计充值：{{ sum.recharge_total_amount }}</div>
        </div>

        <div class="tline"></div>
        <div style="margin-left: 10px">
            <div class="f1">当前收益：{{ sum.income_amount }}</div>
            <div class="f1">累计收益：{{ sum.income_total_amount }}</div>
        </div>

    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            user_id: '',
            sum: {}
        }
    },
    methods: {
        initData() {
            doRequest('/user/summarized', {
                user_id: this.userId,
            }, this.$route.query).then(res => {
                this.sum = res
            })
        },
    },
    mounted() {
        this.userId = this.$route.query['userId']
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

.top {
    width: 375px;
    height: 70px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
}

.top .t1 {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: black;
    margin-left: 10px;
}

.top .t2 {
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.top .t2 .t21 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.top .t2 .t22 {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.tline {
    margin-top: 6px;
    margin-bottom: 17px;
    width: 375px;
    height: 1px;
    background: #DDDDDD;
}

.f1 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}
</style>